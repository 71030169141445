import React, { useContext, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { getAppConfig } from 'app-config'
import { getAuthClient } from 'auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'
import PropTypes from 'prop-types'
import { useNavigate, useRouter } from 'utils'
import { AsyncAutocomplete } from 'frame/components'
import KeyValue from 'frame/components/mui-textfield'
import LeaseTypeDetails from './lease-type-details'
import { constants } from '../../../module'
import { useSelectedFleetIds } from "features/settings"
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

import { FormContext } from '../form'
import { NewVehicleSummaryContext } from '../new'
import { ItemVehicleSummaryContext } from '../../item/item'

import Button from "@mui/material/Button"
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MuiFormSelect from "frame/components/mui-form-select"
import Dialog  from '@mui/material/Dialog'
import DialogContent  from '@mui/material/DialogContent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { useTheme } from "@mui/material/styles"
import Spinner from 'react-md-spinner'

const LeaseInformation = ({data}) => {
  const newContext = useContext(NewVehicleSummaryContext);
  const itemContext = useContext(ItemVehicleSummaryContext);
  const formContext = useContext(FormContext);
  const {
    values,
    onInputChange,
    onSelectChange,
    setValues,
  } = formContext

  const theme = useTheme()
  const navigate = useNavigate()
  const { match } = useRouter()
  const config = getAppConfig();
  const authClient = getAuthClient();
  
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const fleetIds = useSelectedFleetIds()
  const requestTypeNew = 'New/Extra vehicle';
  const requestTypeReplacement = 'Replacement';
  
  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const onClose = navigate(constants.PATH_ALL)

  const buildApiEndpoint = ( path, fleetIds) => {
    const params = new URLSearchParams();
    if (fleetIds && fleetIds.length > 0) {
      params.append('FleetId', fleetIds.join(','));
    }
    return `${path}?${params.toString()}`;
  };

  const apiEndpoint = buildApiEndpoint('/api/lookup/vehicleregistration', fleetIds);

  const fetchOrderDetails = async (agreementNo, vehicleRegistration) => {
    setLoading(true);
    try {
      const accessToken = await authClient.getTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const response = await axios.get(`${config.app.api}contract/contract/${agreementNo}`, {
        params: { FleetId: fleetIds.join(',') },
        headers: headers,
      });
      const data = response.data.data;
      setValues((prevValues) => ({
        ...prevValues,
        lproceed: values.requestType === requestTypeNew ? true : false,
        requestType: values.requestType,
        leaseType1: data.agreementType,
        term1: data.term?.toString() ?? '',
        kms1: data.contractKm?.toString() ?? 0,
        numberOfTyres1: data.noOfTyres?.toString() ?? 0,
        year: data.year,
        make1: data.make,
        model1: data.model,
        colour1: data.colour,
        drivetrain1: "Petrol", //ToDo
        transmission1: "Manual", //ToDo
        variant1: data.model, //ToDo
        vehicleRegistration: vehicleRegistration,
      }));
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedOptionChange = (selectedOption) => {
    fetchOrderDetails(selectedOption.agreementNo, selectedOption);
  };

  const handleCopyAllFields = () => {
    setValues((prevValues) => ({
      ...prevValues,
      leaseType : values.leaseType1,
      term: values.term1,
      kms: values.kms1,
      numberOfTyres: values.numberOfTyres1,
    }));
  };

  const handleProceed = () => {
    if(values.requestType === requestTypeNew && !values.lproceed){
      setValues((prevValues) => ({
        ...prevValues,
        lproceed : true,
      }));
    } else {
      navigate(`${match.url}${constants.PATH_VEHICLE_INFORMATION}`)(0);
      if(data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft'){
        itemContext.setActiveTab("vehicle_information");
      } else{
        newContext.setActiveTab("vehicle_information");
        newContext.setDisabledTabs({
          vehicleInformation: false
        });
      }
    }
  }

  const handleSaveAndExit = () => {
    setValues({
      ...values,
      requestType: values.requestType,
      vehicleRegistration: (values.vehicleRegistration?.value) ?? null,
      leaseType: values.leaseType,
      term: values.term,
      odometer: values.kms === "" ? 0 : values.kms,
      numberOfTyres: values.numberOfTyres === "" ? 0 : values.numberOfTyres,
    });
    if(typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
      values.vehicleRegistration = values.vehicleRegistration?.value;
    }
    setConfirmDialogOpen(true);
  }

  useEffect(() => {
    const vehicleOrderDetails = data?.vehicleOrderDetails;
    if(vehicleOrderDetails && vehicleOrderDetails.status === 'Draft' ){
      const initialVehicleRegistration = {
        agreementNo: null,
        text: vehicleOrderDetails.vehicleRegistration || null,
        value: vehicleOrderDetails.vehicleRegistration || null,
      };
      setValues((values) => ({
        ...values,
        lproceed: vehicleOrderDetails.requestType === requestTypeNew ? true: false,
        requestType: vehicleOrderDetails.requestType,
        vehicleRegistration: initialVehicleRegistration,
        term: vehicleOrderDetails.term,
        term1: vehicleOrderDetails.term,
        kms: vehicleOrderDetails.odometer,
        kms1: vehicleOrderDetails.odometer,
        numberOfTyres: vehicleOrderDetails.numberOfTyres,
        numberOfTyres1: vehicleOrderDetails.numberOfTyres,
        year: vehicleOrderDetails.year,
        make: vehicleOrderDetails.make,
        make1: vehicleOrderDetails.vehicleRegistration ? vehicleOrderDetails.make : '',
        model: vehicleOrderDetails.model,
        model1: vehicleOrderDetails.model,
        colour: vehicleOrderDetails.colour,
        colour1: vehicleOrderDetails.colour,
        year: vehicleOrderDetails.year,
        driveTrain: vehicleOrderDetails.driveTrain,
        transmission: vehicleOrderDetails.transmission,
        variant: vehicleOrderDetails.variant,
        driverName: vehicleOrderDetails.driverName,
        driverEmail: vehicleOrderDetails.driverEmail,
        notes: vehicleOrderDetails.notes,
        leaseType: vehicleOrderDetails.leaseType,
        leaseType1: vehicleOrderDetails.leaseType,
        id: vehicleOrderDetails.id,
      }));
    }
  }, [data]);

  return (
    <>
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1}>
          <Controller
            name="requestType"
            control={methods.control}
            rules={{ required: true }}
            render={({ field }) => (
              <MuiFormSelect
                {...field}
                id="requestType"
                disabled={false}
                label="Request Type"
                labelId="requestType-label"
                options={[requestTypeNew, requestTypeReplacement]}
                value={values.requestType || ''}
                onChange={onInputChange}
              />
            )}
          />
        </Stack>
        <Stack flex={1}>
          <AsyncAutocomplete
            id="vehicleRegistration"
            name="vehicleRegistration"
            control={methods.control}
            label={values.requestType === requestTypeReplacement ? "Registration of replaced vehicle" : "Registration to replicate another vehicle"}
            apiEndpoint={apiEndpoint}
            onSelectedOptionChange={handleSelectedOptionChange}
            rules={{ required: 'This field is required' }}
            disabled={!values.requestType}  
            value={values.vehicleRegistration || null}
            getOptionLabel={(option) => option.value}                          
          />
        </Stack>
      </Stack>
      {values.requestType === requestTypeNew && !values.lproceed && !values.vehicleRegistration && !loading &&
        <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
          <Typography variant="h6">
            Please enter a rego for the replicated or replaced vehicle or click proceed
          </Typography>
        </Stack>
      }
      {values.requestType === requestTypeReplacement && !values.vehicleRegistration && !loading &&
        <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
          <Typography variant="h6">
              Please enter a rego for the replicated or replaced vehicle
          </Typography>
        </Stack>
      }
      {loading && 
        <>
          <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
            <Spinner
              singleColor={theme.palette.black}
              size={18}
            />
            <Typography variant="h6" paddingLeft={1}>
              Loading Information
            </Typography>
          </Stack>
        </>
      }
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        {(values.vehicleRegistration?.value || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft' && data?.vehicleOrderDetails?.vehicleRegistration)) && (
          <>
            <Stack width="50%">
              <Stack flex={1} paddingTop={2}>
                <Typography variant="h5" fontWeight={600}>
                  {values.vehicleRegistration?.value ?? data?.vehicleOrderDetails?.vehicleRegistration} {values.year} {values.make1} {values.model1} {values.colour1}
                </Typography>
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <Controller
                    name="leaseType1"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiFormSelect
                        {...field}
                        id="leaseType1"
                        disabled={true}
                        label="Type of Lease"
                        labelId="leaseleaseType1-label"
                        options={["Fully Maintained Operating Lease", "Non Maintained Operating Lease"]}
                        value={values.leaseType1 || ''}
                      />
                    )}
                  />
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="term1"
                  label='Term'
                  disabled={true}
                  value={values.term1 || ''}
                />
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="kms1"
                  label='Kms'
                  disabled={true}
                  value={values.kms1 || ''}
                />
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="numberOfTyres1"
                  label='Number of Tyres'
                  disabled={true}
                  value={values.numberOfTyres1 || ''}
                />
              </Stack>
            </Stack>
          </>
        )}
        {(values.lproceed || values.vehicleRegistration || data?.vehicleOrderDetails?.status) && (
          <>
            <Stack width="50%">
              <Stack flex={1} paddingTop={2}>
                <Typography variant="h5" fontWeight={600}>
                  {"New Request"}
                </Typography>
              </Stack>            
              <Stack flex={1} paddingTop={2} direction="row" alignItems="center" spacing={1}>
                <Stack width="90%">
                  <Controller
                    name="leaseType"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiFormSelect
                        {...field}
                        id="leaseType"
                        disabled={false}
                        label="Type of Lease"
                        labelId="leaseleaseType-label"
                        options={["Fully Maintained Operating Lease", "Non Maintained Operating Lease"]}
                        value={values.leaseType || ''}
                        onChange={( event ) => {
                          onSelectChange("leaseType", event?.target?.value);
                          setValues((prevValues) => ({
                            ...prevValues,
                            leaseType: event?.target?.value,
                            numberOfTyres : event?.target?.value === 'Fully Maintained Operating Lease' && prevValues.kms > 30000 ? '4' : '0',
                          }));
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack width="10%">
                  <IconButton onClick={() => setOpen(true)} size="small" color='primary'>
                    <InfoOutlinedIcon sx={{  fontSize: 'xx-large', alignItems: 'right'}} />
                  </IconButton>
                  <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                      <LeaseTypeDetails onCancel={() => setOpen(false)}/>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="term"
                  label='Term'
                  value={values.term || ''}
                  inputProps={{ maxLength: 50 }}
                  onChange={onInputChange}
                />
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="kms"
                  label='Kms'
                  value={values.kms || ''}
                  inputProps={{ maxLength: 50 }}
                  onChange={(event) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      kms: event?.target?.value,
                      numberOfTyres : prevValues.leaseType === 'Fully Maintained Operating Lease' ? (event?.target?.value > 30000 ? '4' : '0') : '0',
                    }));
                  }}
                />
              </Stack>
              <Stack flex={1} paddingTop={2}>
                <KeyValue
                  id="numberOfTyres"
                  label='Number of Tyres'
                  value={values.numberOfTyres || ''}
                  inputProps={{ maxLength: 50 }}
                  disabled={values.leaseType === 'Non Maintained Operating Lease'}
                  onChange={onInputChange}
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      <Stack paddingTop={2}>

      </Stack>
      {(values.vehicleRegistration?.value || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft' && data?.vehicleOrderDetails?.vehicleRegistration)) && (
        <>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingBottom={2}>
            <Stack width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={handleCopyAllFields}
              >
                Copy All Fields
              </Button>
            </Stack>
          </Stack>
        </>
      )}
      {(values.lproceed || values.vehicleRegistration || data?.vehicleOrderDetails?.status) && (
        <>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
            <Stack width="50%">
              <Stack flex={1}>
                <KeyValue
                  id="driverName"
                  label='Driver Name'
                  value={values.driverName || ''}
                  inputProps={{ maxLength: 50 }}
                  onChange={onInputChange}
                />
              </Stack>
            </Stack>
            <Stack width="50%" flex={1}>
              <KeyValue
                id="driverEmail"
                label='Driver Email'
                value={values.driverEmail || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
              />
            </Stack>
          </Stack>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
            <Stack width="100%">
              <KeyValue
                id="notes"
                label='Notes'
                value={values.notes || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
      <Stack
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        gap={1}
      >
        <Stack width={{ xs: "100%", md: "205px" }}>
          <Button
            fullWidth
            color="error"
            variant="outlined"
            onClick={() => setConfirmSaveDialogOpen(true)}
          >
            Close
          </Button>
        </Stack>
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          gap={1}
        >
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="outlined"
              disabled={!values.requestType || (values.requestType == requestTypeReplacement && !values.vehicleRegistration) || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Submitted')}
              onClick={handleSaveAndExit}
            >
              Save and Exit
            </Button>
          </Stack>
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="primary"
              id="lproceed"
              variant="contained"
              disabled={!values.requestType || (values.requestType == requestTypeReplacement && !values.vehicleRegistration)}
              onClick={handleProceed}
              value={values.lproceed}
            >
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <StyledModalV2
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          onClose();
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Draft' ? 'Vehicle order request updated successfully.' : 'Vehicle order request created successfully.'}
          </Typography>
        }
      />
      <StyledModalV2
        open={confirmSaveDialogOpen}
        onClose={() => setConfirmSaveDialogOpen(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setConfirmSaveDialogOpen(false)}
            onConfirm={() => {
              setConfirmSaveDialogOpen(false);
              onClose();
            }}
          />
        }
      />
    </>
  )
}

LeaseInformation.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default LeaseInformation