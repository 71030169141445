import PropTypes from 'prop-types'


export const model = [
{
  key: 'id',
  label: 'ID',
  prop: {
    type: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    value: '',
  }
},
{
  key: 'requestType',
  label: 'Request Type',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'term',
  label: 'Term',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'odometer',
  label: 'Km',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'numberOfTyres',
  label: 'No. of tyres',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'make',
  label: `Make`,
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'model',
  label: 'Model',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'variant',
  label: 'Variant',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, 
{
  key: 'colour',
  label: `Colour`,
  column: {
    visible: false,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'driveTrain',
  label: 'Drive Train',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},  {
  key: 'transmission',
  label: 'Transmission',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'createdAt',
  label: 'Date Requested',
  column: {
    visible: true,
    width: 160,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
},
{
  key: 'status',
  label: 'Status',
  column: {
    visible: true,
    width: 110,
  },
  prop: {
    type: PropTypes.string,
    value: '',
  }
}, 
]

export const formTabs = [
  {
    value: "lease_information",
    label: "Lease Information"
  },
  {
    value: "vehicle_information",
    label: "Vehicle Information"
  },
  {
    value: "additional_services",
    label: "Additional Services",
  },
  {
    value: "accessories",
    label: "Accessories",
  }
]

const data = {
  title: 'Vehicle Request Summary',
  breadcrumbs: null,
  backgroundImage: 'url("/static/images/backgrounds/bg4.png")',
  idKey: 'id',
  api: {
    //filter: '/filteroptions/vehiclesummary',
    resource: `/Report/GetAllVehicleRequest`,
    download: `/download/VehicleRequestSummary`,
  },
  model,
  headers: [
    'requestType', 'term', 'odometer', 'numberOfTyres','make','model', 'variant', 'colour', 'driveTrain',
    'transmission', 'createdAt', 'status',
  ],
  filters: [
  ],
}


export default data
