import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { OfflineLookupField, ScrollToTop } from "frame/components"
import moment from "moment"

import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import MuiTextfield from "frame/components/mui-textfield"
import DatePicker from "frame/components/date-picker-v5"

dayjs.extend(advancedFormat)

/* used in add/edit driver form  */
const ConfirmForm = ({
  values,
  setValues,
  fleetFormState,
  // flags
  disabled,
  selectedVehicle,
  hasVehicleAlready,
  // functions
  onSubmit,
  onBack,
  onClose,
  onLookup,
}) => {
  const theme = useTheme()

  const onLookupChange = (key) => (option) =>
    setValues({ ...values, [key]: option ? option.value : null })

  const onInputChange = ({ target: { id, name, value } }) =>
    setValues({ ...values, [id || name]: value })

  const onDateChange = (value) => {
    setValues({ ...values, startDate: value })
  }

  const onSubmitForm = () =>
    onSubmit({
      ...values,
      effectiveDate: values.startDate || moment(),
    })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack>
        <ScrollToTop />
        <Card
          sx={{
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <CardContent
            sx={{
              p: 3,
            }}
          >
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              gap={3}
            >
              <Stack flex={1}>
                <Stack gap={1}>
                  <Typography variant="h5" fontWeight={600}>
                    Change Driver Details
                  </Typography>
                  <Stack
                    border="1px solid"
                    borderColor={theme.palette.accent.sky}
                    padding={2}
                    borderRadius={1}
                  >
                    <Stack>
                      <Typography
                        variant="bodyMedium"
                        color={theme.palette.accent.sky}
                      >
                        Current Driver
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        fontWeight={600}
                      >
                        {selectedVehicle && selectedVehicle.currentDriver ? selectedVehicle.currentDriver : "unassigned"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack flex={1} gap={1}>
                <Typography variant="h5" fontWeight={600}>
                  Change Driver To
                </Typography>
                <Stack
                  border="1px solid"
                  borderColor={theme.palette.accent.sky}
                  padding={2}
                  borderRadius={1}
                  gap={2}
                >
                  <Stack>
                    <Typography
                      variant="bodyMedium"
                      color={theme.palette.accent.sky}
                    >
                      New Driver
                    </Typography>
                    <Typography
                      variant="bodyLarge"
                      fontWeight={600}
                    >
                      {values.driver || "-"}
                    </Typography>
                  </Stack>
                  <Stack>
                    <DatePicker
                      label="Start Date"
                      disableFuture
                      format="DD/MM/YYYY"
                      minDate={typeof fleetFormState?.startDate === "undefined"
                        ? dayjs().add(-90, "days")
                        : dayjs(fleetFormState?.startDate)
                      }
                      value={values?.startDate ? values?.startDate : null}
                      onChange={onDateChange}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack flex={1} gap={1} pt={3}>
              <Typography variant="h5" fontWeight={600}>
                Vehicle Details
              </Typography>
              <Stack
                border="1px solid"
                borderColor={theme.palette.accent.sky}
                padding={2}
                borderRadius={1}
                gap={2}
              >
                <Stack flexDirection="row" gap={3}>
                  <Stack flex={1}>
                    <Typography
                      variant="bodyMedium"
                      color={theme.palette.accent.sky}
                    >
                      Vehicle
                    </Typography>
                    <Typography
                      variant="bodyLarge"
                      fontWeight={600}
                    >
                      {selectedVehicle ? selectedVehicle.vehicle : "-"}
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography
                      variant="bodyMedium"
                      color={theme.palette.accent.sky}
                    >
                      Registration
                    </Typography>
                    <Typography
                      variant="bodyLarge"
                      fontWeight={600}
                    >
                      {selectedVehicle ? selectedVehicle.text : "-"}
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography
                      variant="bodyMedium"
                      color={theme.palette.accent.sky}
                    >
                      Colour
                    </Typography>
                    <Typography
                      variant="bodyLarge"
                      fontWeight={600}
                    >
                      {selectedVehicle ? selectedVehicle.colour : "-"}
                    </Typography>
                  </Stack>
                </Stack>
                {/* Form Fields */}
                <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                  <Stack flex={1} gap={2}>
                    <Stack>
                      <OfflineLookupField
                        fullWidth showAllResult allowFreeText
                        size="small"
                        id="costCentre"
                        label="Cost Centre"
                        disabled={!values.accountName || disabled}
                        customFleetId={values.thirdPartyNumber}
                        lookupPath="drivercostcentre"
                        value={values.costCentre || ""}
                        variant="outlined"
                        onChange={onLookupChange("costCentre")}
                        defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    </Stack>
                    <Stack>
                      <OfflineLookupField
                        fullWidth showAllResult
                        size="small"
                        id="location"
                        label="Location"
                        disabled={!values.accountName || disabled}
                        lookupPath="location"
                        value={values.location || ""}
                        variant="outlined"
                        onChange={onLookupChange("location")}
                        defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    </Stack>
                    <Stack>
                      <OfflineLookupField
                        fullWidth showAllResult allowFreeText
                        id="controller"
                        size="small"
                        label="Fleet Controller"
                        disabled={disabled}
                        customFleetId={values.thirdPartyNumber}
                        lookupPath="driverfleetcontroller"
                        value={values.controller || ""}
                        variant="outlined"
                        onChange={onLookupChange("controller")}
                        defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
                        onLookup={onLookup}
                        resourceModel="drivers"
                      />
                    </Stack>
                  </Stack>
                  <Stack flex={1} height="100%">
                    <MuiTextfield
                      multiline
                      fullWidth
                      rows={3}
                      size="small"
                      id="vehicleNotes"
                      label="Vehicle Notes"
                      disabled={disabled}
                      value={values.vehicleNotes || ""}
                      inputProps={{ maxLength: 50 }}
                      onChange={onInputChange}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack py={3}>
              <Stack gap={2}>
                <Typography variant="bodyLarge">
                  *Please note, there cannot be more than one driver assigned to a vehicle.
                </Typography>
                <Typography variant="bodyLarge">
                  You have chosen to change the driver {selectedVehicle?.currentDriver && <>from <strong>{selectedVehicle?.currentDriver}</strong></>}{" to "}
                  <strong>{values.driver || "unassigned"}</strong>. Would you like to proceed?
                </Typography>
              </Stack>
            </Stack>

            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />

            <Stack
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Box width={{ xs: "100%", sm: "205px" }}>
                <Button
                  fullWidth
                  color="secondary"
                  disabled={disabled}
                  variant="contained"
                  onClick={onBack}
                >
                  Back
                </Button>
              </Box>
              <Stack flexDirection="row" gap={2}>
                <Box width={{ xs: "100%", sm: "205px" }}>
                  <Button
                    fullWidth
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={onSubmitForm}
                  >
                    {`Yes, ${hasVehicleAlready ? "Change" : "Assign"} the Driver`}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </LocalizationProvider>
  )
}

ConfirmForm.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  fleetFormState: PropTypes.object,
  // flags
  disabled: PropTypes.bool,
  selectedVehicle: PropTypes.object,
  hasVehicleAlready: PropTypes.bool,
  // functions
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
}


const mapStateToProps = ({ fleet }) => ({
  fleetFormState: fleet?.form,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmForm)
