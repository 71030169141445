import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { trimObjectStrings } from "utils"
import { fetchServiceHistory, submitKmReading, reset, downloadVehicleHistory, fetch } from "features/fleet/actions"
import { fetchServiceBooking } from "features/report-vehicle-servicing/actions"
import { useEnabledFeatureToggleList } from "features/settings"
import { isMiles } from "utils/sourceSystem"
import { hasRoles } from "acl"

// components
import VehicleHistoryTable from "./vehicle-history/vehicle-history-table"
import Odometer from "./vehicle-odometer-reading/odometer"
import AddKmReadingForm from "../service-bookings/form/add-km-reading-form"
import AddServiceRecordForm from "../service-bookings/form/add-service-record-form"
import { SnackbarPopup } from "frame/components"
import { useIsAlfaDownDisableFeatures } from "features/drivers"
import VehicleView from "./vehicle-view"
import VehicleHistory from "./vehicle-history"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import VehicleContractTyres from "./vehicle-contract-tyres"
import VehicleOdometerReading from "./vehicle-odometer-reading"
import StyledModalV2 from "frame/components/styled-modal-v2"

const viewConstants = {
  VEHICLE_DETAILS: "VEHICLE_DETAILS", // default
  ADD_KM_READING_FORM: "ADD_KM_READING_FORM",
  ADD_SERVICE_RECORD_FORM: "ADD_SERVICE_RECORD_FORM",
}

const VehicleDetails = ({ data, flags, onFetchServiceHistory, onReset, onFetchServiceBooking, onDownloadVehicleHistory, downloadStatus, fleetData, onFetch, userRoles }) => {
  const isMilesAgreement = isMiles(data.sourceSystem);
  const featureToggleList = useEnabledFeatureToggleList()
  const kmExceptionsFeatureToggle = featureToggleList.includes("KMExceptions")
  const disableOutOfHoursFeatureToggle = featureToggleList.includes("OutOfHoursDisabled") // "ALFA out of hours DISABLE FEATURES" limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false

  const [addNewKmReadingModal, setAddNewKmReadingModal] = useState(false)
  const [activeView, setActiveView] = useState(viewConstants.SERVICE_BOOKING_MENU)
  const [odoData, setOdoData] = useState(data)
  
  const noOfTyres = data.noOfTyres || 0

  useEffect(() => { // on load
    onReset() // reset flags
    onFetchServiceBooking(data.agreementNo) // fetch service booking data
  }, []) // eslint-disable-line

  useEffect(() => {
    data.registration && data.registration !== "(Unknown)" && onFetchServiceHistory(data.registration, data.thirdPartyNumber)
    data.agreementNo && onFetchServiceBooking(data.agreementNo)
  // eslint-disable-next-line
  }, [onFetchServiceHistory])

  const onAddKmReadingClick = () => setAddNewKmReadingModal(true)
  const onRecentlyCompletedServiceClick = () => {
    setAddNewKmReadingModal(false);
    setActiveView(viewConstants.ADD_SERVICE_RECORD_FORM)
  }
  const addNewKmClick = () => {
    setAddNewKmReadingModal(false);
    setActiveView(viewConstants.ADD_KM_READING_FORM)
  }
  const onDownloadClick = () => {
    onDownloadVehicleHistory(data.thirdPartyNumber,data.registration);
  }
  const updateOdoData = (lastReadingDate, lastReading) => {
    const updatedData = {...odoData,lastKmReading:lastReadingDate,lastOdoReading:lastReading}
    setOdoData(updatedData)
  }

  return (
    <>
      {activeView === viewConstants.ADD_KM_READING_FORM ? (
        <AddKmReadingForm      
          fleetData={fleetData}
          isMiles={isMilesAgreement}  
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            onFetchServiceHistory(fleetData.registration, fleetData.thirdPartyNumber)
          }}
        />
      ) : activeView === viewConstants.ADD_SERVICE_RECORD_FORM ? (
        <AddServiceRecordForm
          fleetData={fleetData}
          onBack={() => {
            setActiveView(viewConstants.SERVICE_BOOKING_MENU)
            onFetchServiceHistory(fleetData.registration, fleetData.thirdPartyNumber)
          }}
        />
      ) : (
        <>
          <VehicleView
            data={data}
            vehicleHistory={
              <VehicleHistory
                actions={
                  <>
                    {kmExceptionsFeatureToggle  && (
                      <Stack flexDirection={{ xs: "column", md: "row" }} gap={1}>
                        <Stack width={{ xs: "100%", md: "160px" }}>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={onDownloadClick}
                          >
                            {"Download Report"}
                          </Button>
                        </Stack>
                        {(hasRoles(["superadmin"], userRoles) || hasRoles(["fleetcontroller"], userRoles)) &&
                          <Stack width={{ xs: "100%", md: "160px" }}>
                            <Button
                              disabled={disabled}
                              variant="contained"
                              fullWidth
                              onClick={onAddKmReadingClick}
                            >
                              {"Add KM Reading"}
                            </Button>
                          </Stack>
                        }
                      </Stack>
                    )}
                  </>
                }
                tableData={
                  <VehicleHistoryTable
                    data={data}
                    agreementNo={data.agreementNo}
                    updateOdoData={updateOdoData}
                  />
                }
              />
            }
            contractTyres={
              <VehicleContractTyres
                noOfTyres={noOfTyres}
                remainingTyres={data.remainingTyres}
              />
            }
            odometerReading={
              <VehicleOdometerReading>
                <Odometer data={odoData} />
              </VehicleOdometerReading>
            }
          />
        </>
      )}

      <StyledModalV2
        open={addNewKmReadingModal}
        onClose={() => setAddNewKmReadingModal(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Are you adding a new KM reading from a recently completed service?
          </Typography>
        }
        actions={
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            gap={1}
          >
            <Box width={{ xs: "100%", md: "180px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={addNewKmClick}
              >
                No
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: "100%", md: "180px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onRecentlyCompletedServiceClick}
                >
                  Yes
                </Button>
              </Box>
            </Stack>
          </Stack>
        }
      />
    
      {downloadStatus && (
        <SnackbarPopup
          coloured
          success={downloadStatus.toLowerCase().includes("success")}
          error={downloadStatus.toLowerCase().includes("error")}
          message={downloadStatus}
        />
      )}
    </>
  )
}

VehicleDetails.propTypes = {
  data: PropTypes.object,
  flags: PropTypes.object.isRequired,
  onFetchServiceHistory: PropTypes.func.isRequired,
  onFetchServiceBooking: PropTypes.func.isRequired,
  onDownloadVehicleHistory: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onReset: PropTypes.func.isRequired,
  onAddKmReading: PropTypes.func.isRequired,
  fleetData: PropTypes.object.isRequired,
}

const mapStateToProps = ({ fleet, reportVehicleServicing, settings }, ownProps) => {
  const agreementNo = ownProps.match.params.id
  let data = fleet.data?.[agreementNo] || null
  
  const vehicleHistory = fleet.relatedVehicleHistory
  if(vehicleHistory && vehicleHistory.length > 0) {
    data.remainingTyres = vehicleHistory[0].remainingTyres
  }

  return {
    data: trimObjectStrings(data), // Because the API does not clean data for us
    flags: reportVehicleServicing.flags,
    fleetFlags: fleet.flags,
    downloadStatus: reportVehicleServicing?.downloadStatus,
    fleetData: fleet.data?.[ownProps.match.params.id],
    userRoles: settings.roles,
}
}

const mapDispatchToProps = (dispatch) => ({
  onFetchServiceHistory: (rego, fleetId) => {
    dispatch(fetchServiceHistory(null, {
      resourceId: rego,
      queryParams: { fleetId }
    }))
  },
  onFetchServiceBooking: (agreementNo) => {
    dispatch(fetchServiceBooking(null, { resourceId: agreementNo }))
  },  
  onDownloadVehicleHistory: (selectedThirdPartyNumber, selectedRegistration) => {
    dispatch(downloadVehicleHistory(null,{ title:"Vehicle History", queryParams: { selectedThirdPartyNumber, selectedRegistration } }))
  },  
  onAddKmReading: (payload) => {
    dispatch(submitKmReading(payload))
  },
  onReset: () => {
    dispatch(reset())
  },
  onFetch: (agreementNo, fleetIds) => {
    const FleetId = fleetIds.join(",")
    dispatch(fetch(null, {
      resourceId: agreementNo,
      queryParams: { FleetId }
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails)
