import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { colors } from '@material-ui/core'
import { Label, FleetIcon } from 'frame/components'
import { getDateColor } from 'listable'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import TableCellView from 'frame/components/table-cell/table-cell'

const getDaysToDelivery = (date) => {
  if ( ! date) {
    return ''
  }
  const a = moment(date)
  const b = moment(new Date())
  return a.diff(b, 'days')
}

const getDayColor = (date) => {
  if (moment(date).isBefore(new Date())) {
    return colors.red[600]
  }
  return colors.grey[600]
}

const Row = ({ account, item, columns }) => {
  // const isPremium = account === 'premium'

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')  

  return (
    <TableRow
      hover
      sx={{
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
       {columns.orderId && (
        <TableCell>
          {item.orderId || '-'}
        </TableCell>
      )}
      {columns.quoteBundle && (
        <TableCellView>
          {item.quoteBundle || '-'}
        </TableCellView>
      )} 
      {columns.agreementNo && (
        <TableCellView>
          {item.agreementNo || '-'}
        </TableCellView>
      )}
      {columns.previousAgreement && (
        <TableCellView>
          {item.previousAgreement || '-'}
        </TableCellView>
      )}
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.poRaisedDate && (
        <TableCellView>
          {item.poRaisedDate ? moment(item.poRaisedDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.requestedDeliveryDate && (
        <TableCellView>
          {item.requestedDeliveryDate ? (
            <Label
              color={getDateColor(item.requestedDeliveryDate)}
              variant="outlined">
              {moment(item.requestedDeliveryDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.expectedDeliveryDate && (
        <TableCellView>
          {item.expectedDeliveryDate ? (
            <Label
              color={getDateColor(item.expectedDeliveryDate)}
              variant="outlined">
              {moment(item.expectedDeliveryDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.previousRegistration && (
        <TableCellView>
          {item.previousRegistration || '-'}
        </TableCellView>
      )}    
      {columns.days && (
        <TableCellView>
          {item.expectedDeliveryDate ? (
            <Label
              color={getDayColor(item.expectedDeliveryDate)}
              variant="outlined">
              {getDaysToDelivery(item.expectedDeliveryDate)}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.location && (
        <TableCellView>
          {item.location || '-'}
        </TableCellView>
      )}
      {/* {(isPremium && columns.sslvNumber) && (
        <TableCellView>
          {item.sslvNumber || '-'}
        </TableCellView>
      )} */}
      {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      }
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
}

export default Row
